module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "feedback-knowledge-js": "static-1.2747",
      "head-dlb": "static-1.2378",
      "hs-test-utils": "static-1.5466",
      "hubspot-dlb": "static-2.514",
      "I18n": "static-7.1303",
      "jasmine-runner": "static-1.3841",
      "quartz": "static-1.4025",
      "react-dlb": "static-1.42",
      "outpost_static": "static-1.2031",
      "PatternValidationJS": "static-1.437",
      "react": "static-7.133",
      "enviro": "static-4.271",
      "hs-promise-rejection-tracking": "static-1.2751",
      "PortalIdParser": "static-2.250",
      "raven": "static-3.3055",
      "raven-hubspot": "static-1.3362",
      "classnames": "static-2.10",
      "hoist-non-react-statics": "static-3.9",
      "hub-http": "static-1.2732",
      "hubspot-url-utils": "static-1.1962",
      "immutable": "static-2.19",
      "metrics-js": "static-1.7033",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "moment": "static-3.26",
      "react-dom": "static-7.85",
      "HeadJS": "static-2.517",
      "foundations-components": "static-1.4154",
      "framer-motion": "static-1.29",
      "hs-test-utils-bend-plugin": "static-1.577",
      "msw": "static-1.39",
      "testing-library": "static-1.112",
      "webpack-env": "static-1.41",
      "i18n-data": "static-1.188",
      "moment-timezone": "static-5.17",
      "react-utils": "static-2.3153",
      "jasmine": "static-4.1765",
      "quartz-core-utils": "static-1.892",
      "StyleGuideUI": "static-3.433",
      "quartz-core": "static-1.3244",
      "csstype": "static-1.8",
      "bend-plugin-foundations-components": "static-1.422",
      "floating-ui": "static-1.19",
      "foundations-assets": "static-1.2258",
      "foundations-theming": "static-1.1367",
      "hs-story-utils": "static-1.6451",
      "react-aria": "static-1.31",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.66",
      "tanstack-table": "static-1.10",
      "ui-fonts": "static-1.327",
      "ui-images": "static-2.765",
      "copilot-toolkit": "static-1.2569",
      "dispatcher": "static-1.105",
      "general-store": "static-6.20",
      "history": "static-4.15",
      "hub-http-janus": "static-1.586",
      "hub-http-shared-msw-handlers": "static-1.5339",
      "hubspotter-http": "static-1.2507",
      "hubspotter-http-shared-msw-handlers": "static-1.5337",
      "icons": "static-2.585",
      "mobile-manifest-mixins": "static-1.289",
      "platform-navigation-bootstrap": "static-1.9265",
      "quick-fetch": "static-1.1826",
      "react-rhumb": "static-1.13963",
      "react-router-dom": "static-5.26",
      "UIComponents": "static-3.5971",
      "foundations-theming-base": "static-1.352",
      "react-input-autosize": "static-2.17",
      "chatspot-client-types": "static-1.6607",
      "chatspot-core": "static-1.11677",
      "ts-schema": "static-1.1911",
      "sassPrefix": "static-1.121",
      "platform-infra-nav-components": "static-1.5608",
      "cssUtils": "static-1.320",
      "HubStyle": "static-2.9331",
      "HubStyleTokens": "static-2.9021",
      "hs-lodash": "static-4.9"
    },
    "depPathPrefixes": {
      "feedback-knowledge-js": "/feedback-knowledge-js/static-1.2747",
      "head-dlb": "/head-dlb/static-1.2378",
      "hs-test-utils": "/hs-test-utils/static-1.5466",
      "hubspot-dlb": "/hubspot-dlb/static-2.514",
      "I18n": "/I18n/static-7.1303",
      "jasmine-runner": "/jasmine-runner/static-1.3841",
      "quartz": "/quartz/static-1.4025",
      "react-dlb": "/react-dlb/static-1.42",
      "outpost_static": "/outpost_static/static-1.2031",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "react": "/react/static-7.133",
      "enviro": "/enviro/static-4.271",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2751",
      "PortalIdParser": "/PortalIdParser/static-2.250",
      "raven": "/raven/static-3.3055",
      "raven-hubspot": "/raven-hubspot/static-1.3362",
      "classnames": "/classnames/static-2.10",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "hub-http": "/hub-http/static-1.2732",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1962",
      "immutable": "/immutable/static-2.19",
      "metrics-js": "/metrics-js/static-1.7033",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "moment": "/moment/static-3.26",
      "react-dom": "/react-dom/static-7.85",
      "HeadJS": "/HeadJS/static-2.517",
      "foundations-components": "/foundations-components/static-1.4154",
      "framer-motion": "/framer-motion/static-1.29",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.577",
      "msw": "/msw/static-1.39",
      "testing-library": "/testing-library/static-1.112",
      "webpack-env": "/webpack-env/static-1.41",
      "i18n-data": "/i18n-data/static-1.188",
      "moment-timezone": "/moment-timezone/static-5.17",
      "react-utils": "/react-utils/static-2.3153",
      "jasmine": "/jasmine/static-4.1765",
      "quartz-core-utils": "/quartz-core-utils/static-1.892",
      "StyleGuideUI": "/StyleGuideUI/static-3.433",
      "quartz-core": "/quartz-core/static-1.3244",
      "csstype": "/csstype/static-1.8",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.422",
      "floating-ui": "/floating-ui/static-1.19",
      "foundations-assets": "/foundations-assets/static-1.2258",
      "foundations-theming": "/foundations-theming/static-1.1367",
      "hs-story-utils": "/hs-story-utils/static-1.6451",
      "react-aria": "/react-aria/static-1.31",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.66",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-fonts": "/ui-fonts/static-1.327",
      "ui-images": "/ui-images/static-2.765",
      "copilot-toolkit": "/copilot-toolkit/static-1.2569",
      "dispatcher": "/dispatcher/static-1.105",
      "general-store": "/general-store/static-6.20",
      "history": "/history/static-4.15",
      "hub-http-janus": "/hub-http-janus/static-1.586",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.5339",
      "hubspotter-http": "/hubspotter-http/static-1.2507",
      "hubspotter-http-shared-msw-handlers": "/hubspotter-http-shared-msw-handlers/static-1.5337",
      "icons": "/icons/static-2.585",
      "mobile-manifest-mixins": "/mobile-manifest-mixins/static-1.289",
      "platform-navigation-bootstrap": "/platform-navigation-bootstrap/static-1.9265",
      "quick-fetch": "/quick-fetch/static-1.1826",
      "react-rhumb": "/react-rhumb/static-1.13963",
      "react-router-dom": "/react-router-dom/static-5.26",
      "UIComponents": "/UIComponents/static-3.5971",
      "foundations-theming-base": "/foundations-theming-base/static-1.352",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "chatspot-client-types": "/chatspot-client-types/static-1.6607",
      "chatspot-core": "/chatspot-core/static-1.11677",
      "ts-schema": "/ts-schema/static-1.1911",
      "sassPrefix": "/sassPrefix/static-1.121",
      "platform-infra-nav-components": "/platform-infra-nav-components/static-1.5608",
      "cssUtils": "/cssUtils/static-1.320",
      "HubStyle": "/HubStyle/static-2.9331",
      "HubStyleTokens": "/HubStyleTokens/static-2.9021",
      "hs-lodash": "/hs-lodash/static-4.9"
    },
    "project": "feedback-knowledge-js",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};