module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "feedback-knowledge-js": "static-1.2251",
      "browserslist-config-hubspot": "static-1.131",
      "csstype": "static-1.8",
      "head-dlb": "static-1.1750",
      "HeadJS": "static-2.485",
      "hs-test-utils": "static-1.4656",
      "hubspot-dlb": "static-1.1772",
      "I18n": "static-7.1240",
      "i18n-data": "static-1.182",
      "jasmine": "static-4.1289",
      "jasmine-runner": "static-1.3147",
      "moment-timezone": "static-5.17",
      "outpost_static": "static-1.1537",
      "PatternValidationJS": "static-1.437",
      "quartz": "static-1.3369",
      "quartz-core-utils": "static-1.236",
      "react-utils": "static-2.2637",
      "StyleGuideUI": "static-3.419",
      "testing-library": "static-1.108",
      "react": "static-7.133",
      "enviro": "static-4.254",
      "hs-promise-rejection-tracking": "static-1.1937",
      "PortalIdParser": "static-2.233",
      "raven": "static-3.2013",
      "raven-hubspot": "static-1.2320",
      "classnames": "static-2.10",
      "hoist-non-react-statics": "static-3.9",
      "hub-http": "static-1.2732",
      "hubspot-url-utils": "static-1.1402",
      "immutable": "static-2.19",
      "metrics-js": "static-1.6084",
      "react-dom": "static-7.85",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "moment": "static-3.26",
      "foundations-theming": "static-1.741",
      "hub-http-janus": "static-1.549",
      "hub-http-rxjs": "static-1.517",
      "HubStyleTokens": "static-2.8197",
      "msw": "static-1.39",
      "redux-mock-store": "static-1.8",
      "redux-thunk": "static-2.17",
      "transmute": "static-2.29",
      "UIComponents": "static-3.5147",
      "rxjs": "static-5.10",
      "cssUtils": "static-1.313",
      "foundations-components": "static-1.2932",
      "hs-story-utils": "static-1.5594",
      "HubStyle": "static-2.8507",
      "icons": "static-2.582",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.61",
      "ui-images": "static-2.739",
      "react-input-autosize": "static-2.17",
      "sassPrefix": "static-1.115",
      "ui-fonts": "static-1.326",
      "framer-motion": "static-1.24",
      "floating-ui": "static-1.5",
      "foundations-assets": "static-1.1637",
      "react-aria": "static-1.7",
      "tanstack-table": "static-1.10"
    },
    "depPathPrefixes": {
      "feedback-knowledge-js": "/feedback-knowledge-js/static-1.2251",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.131",
      "csstype": "/csstype/static-1.8",
      "head-dlb": "/head-dlb/static-1.1750",
      "HeadJS": "/HeadJS/static-2.485",
      "hs-test-utils": "/hs-test-utils/static-1.4656",
      "hubspot-dlb": "/hubspot-dlb/static-1.1772",
      "I18n": "/I18n/static-7.1240",
      "i18n-data": "/i18n-data/static-1.182",
      "jasmine": "/jasmine/static-4.1289",
      "jasmine-runner": "/jasmine-runner/static-1.3147",
      "moment-timezone": "/moment-timezone/static-5.17",
      "outpost_static": "/outpost_static/static-1.1537",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "quartz": "/quartz/static-1.3369",
      "quartz-core-utils": "/quartz-core-utils/static-1.236",
      "react-utils": "/react-utils/static-2.2637",
      "StyleGuideUI": "/StyleGuideUI/static-3.419",
      "testing-library": "/testing-library/static-1.108",
      "react": "/react/static-7.133",
      "enviro": "/enviro/static-4.254",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.1937",
      "PortalIdParser": "/PortalIdParser/static-2.233",
      "raven": "/raven/static-3.2013",
      "raven-hubspot": "/raven-hubspot/static-1.2320",
      "classnames": "/classnames/static-2.10",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "hub-http": "/hub-http/static-1.2732",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1402",
      "immutable": "/immutable/static-2.19",
      "metrics-js": "/metrics-js/static-1.6084",
      "react-dom": "/react-dom/static-7.85",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "moment": "/moment/static-3.26",
      "foundations-theming": "/foundations-theming/static-1.741",
      "hub-http-janus": "/hub-http-janus/static-1.549",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.517",
      "HubStyleTokens": "/HubStyleTokens/static-2.8197",
      "msw": "/msw/static-1.39",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-thunk": "/redux-thunk/static-2.17",
      "transmute": "/transmute/static-2.29",
      "UIComponents": "/UIComponents/static-3.5147",
      "rxjs": "/rxjs/static-5.10",
      "cssUtils": "/cssUtils/static-1.313",
      "foundations-components": "/foundations-components/static-1.2932",
      "hs-story-utils": "/hs-story-utils/static-1.5594",
      "HubStyle": "/HubStyle/static-2.8507",
      "icons": "/icons/static-2.582",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.61",
      "ui-images": "/ui-images/static-2.739",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "sassPrefix": "/sassPrefix/static-1.115",
      "ui-fonts": "/ui-fonts/static-1.326",
      "framer-motion": "/framer-motion/static-1.24",
      "floating-ui": "/floating-ui/static-1.5",
      "foundations-assets": "/foundations-assets/static-1.1637",
      "react-aria": "/react-aria/static-1.7",
      "tanstack-table": "/tanstack-table/static-1.10"
    },
    "project": "feedback-knowledge-js",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};